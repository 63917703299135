.main {
  min-width: 1200px;

  &.customer,
  &.advisor {
    padding-left: 250px;
  }
}

.wrapper {
  overflow: auto;
}

button.hidden,
button.hidden:hover {
  background-color: transparent !important;
  border: none;
  color: transparent !important;
  cursor: default;
}

.documents-tab {
  .tab-content,
  .nav-link {
    background: transparent;
    box-shadow: none;
  }

  .tab-content {
    border: 1px solid #ddd;
  }

  .nav-item {
    &:first-child {
      border-top-left-radius: 0.75rem;
    }

    &:last-child {
      border-top-right-radius: 0.75rem;
    }

    &.active {
      font-weight: 500;
    }
  }

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    font-weight: 500;
  }
}

.brand-img {
  border-radius: 0.5rem;
}

.navbar {
  .sidebar-brand {
    max-width: 200px;
    background: transparent;
  }
}

.text-custom-dark {
  color: #454545;
}

.text-custom-light {
  color: #7e7e7e;
}

.text-custom-red {
  color: #dc3545;
}

.sidebar-item.active > .sidebar-link {
  .sidebar-icon {
    color: #dc3545 !important;
  }
}

.sidebar-link {
  .sidebar-icon {
    color: #7e7e7e !important;
  }

  &:hover {
    .sidebar-icon {
      color: #dc3545 !important;
    }
  }
}

.new-asset-card {
  font-size: 1.5rem;
  height: 100%;

  &:hover {
    background: #f8f9fa;
    border-color: #3e4676;
  }

  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.min-width-50 {
  min-width: 50px;
}

.min-width-300 {
  min-width: 300px;
}

.box-shadow-none {
  box-shadow: none !important;
}

.investor-details,
.advisor-details {
  .modal-title {
    flex-grow: 1;
  }
}

.min-vh-20 {
  min-height: 20vh !important;
}

.min-height-50 {
  min-height: 50%;
}

.min-height-300p {
  min-height: 300px;
}

.bg-transparent {
  background: transparent !important;
}

.bg-lighter {
  background: #eeeeee4f !important;
}

.font-size-75 {
  font-size: 0.75rem;
}

.font-size-85 {
  font-size: 0.85rem;
}

.font-size-95 {
  font-size: 0.95rem;
}

.font-size-6 {
  font-size: 0.6rem;
}

.font-size-1-1 {
  font-size: 1.1rem;
}

.font-size-1-2 {
  font-size: 1.2rem;
}

.funds-tabs {
  .nav-item.active {
    box-shadow: 0 2px 4px rgba(126, 142, 177, 0.12);
  }

  .nav-link:hover {
    border: none;
  }
}

.programs-tabs {
  .nav-item.active {
    box-shadow: 0 2px 4px rgba(126, 142, 177, 0.12);
  }

  .nav-link:hover {
    border: none;
  }
}

.categories-tabs {
  .nav-item.active {
    box-shadow: 0 2px 4px rgba(126, 142, 177, 0.12);
  }

  .nav-link:hover {
    border: none;
  }
}

.categories-tabs-content {
  th {
    border: 0;
  }
}

.users-tabs {
  .nav-item.active {
    box-shadow: 0 2px 4px rgba(126, 142, 177, 0.12);
  }

  .nav-link:hover {
    border: none;
  }
}

.users-tabs-content {
  th {
    border: 0;
  }
}

.custom-table {
  th {
    border: 0;
  }
}

.my-account {
  form {
    z-index: 1;
  }
}

.user-name {
  width: 80px;
  height: 80px;
  font-size: 2rem;
}

.sponsor-logo-preview {
  max-width: 150px;
}

.sponsor-image-program-detail {
  max-width: 250px;
  height: 100px;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.align-content-evenly {
  align-content: space-evenly;
}

.account-tabs {
  .nav-item.active {
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.05);
  }

  .tab-content {
    padding: 0;
    background: transparent;
    box-shadow: none;

    .tab-card {
      border-top-left-radius: 0;
    }

    th {
      border: 0;
    }
  }
}

.word-break-all {
  word-break: break-all;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

.width-200 {
  width: 200px;
}

.height-100 {
  height: 100%;
}

.max-height-100 {
  max-height: 100px;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-150 {
  max-width: 150px;
}

.max-width-100 {
  max-width: 100px;
}

.width-2em {
  width: 2em;
}

.statements-modal,
.close-documents-modal {
  .modal-title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.z-index-2 {
  z-index: 2;
}

.z-index-0 {
  z-index: 0;

  .pagination {
    z-index: 0;
  }
}

.statements-progress {
  .close {
    display: none;
  }
}

.hide-selector {
  .custom-switch .custom-control-label::before {
    top: calc(0.20312rem + -2px);
  }

  .custom-switch .custom-control-label::after {
    top: calc(0.20312rem + 0px);
  }
}

.content.wdr-ui-element {
  height: calc(100% - 75px - 62px);
  margin: 0;
}

.wdr-ui-element:not(.wdr-cell) {
  border-radius: 2px !important;
}

#wdr-grid-title {
  color: #3b7ddd !important;
  font-size: 18px !important;
}

.accessCheckbox .custom-control-label {
  padding-top: 2px;
}

.ceo-letter {
  p {
    margin-bottom: 0 !important;
  }
  img {
    max-width: 100%;
  }
}

.margin-0-auto {
  margin: 0 auto;
}

.sidebar {
  position: fixed;
  width: 250px;
  height: 100%;
  overflow-y: auto;
}

#question,
#letter,
#program-email {
  .ql-toolbar {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #dee2e6 !important;
    font-family: inherit;
  }

  .ql-container {
    font-family: inherit;
    border: none !important;
  }
}

.question-content {
  p {
    margin-bottom: 0;
  }
}

.brightness-50 {
  filter: brightness(50%);
}

.email-preview {
  img {
    max-width: 100%;
  }
}

.activity-table {
  overflow: auto;
  display: block;
  table-layout: auto;
}

.color-picker {
  padding: 5px;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}

.color-box {
  width: 36px;
  height: 14px;
  border-radius: 2px;
}

.color-selector {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.sort {
  white-space: nowrap;
  cursor: pointer;
}

.sort:after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik02LjUyNiAzLjVjLjQyMyAwIC42MzUtLjQzOC4zMy0uNzA4TDMuODQuMTI1YS41MjkuNTI5IDAgMDAtLjY4MiAwTC4xNDUgMi43OTJjLS4zMDYuMjctLjA5NC43MDguMzMuNzA4aDYuMDV6TS40NzQgNS41Yy0uNDIzIDAtLjYzNS40MzgtLjMzLjcwOEwzLjE2IDguODc1YS41MjkuNTI5IDAgMDAuNjgyIDBsMy4wMTQtMi42NjdjLjMwNi0uMjcuMDk0LS43MDgtLjMzLS43MDhILjQ3NnoiIGZpbGw9IiNCNkMyRDIiLz48L3N2Zz4=");
  margin-left: 0.25rem;
}

.sort.desc:after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0uNDc0LjVjLS40MjMgMC0uNjM1LjQzOC0uMzMuNzA4TDMuMTYgMy44NzVhLjUyOS41MjkgMCAwMC42ODIgMGwzLjAxNC0yLjY2N0M3LjE2MS45MzggNi45NS41IDYuNTI1LjVILjQ3NnoiIGZpbGw9IiNCNkMyRDIiLz48L3N2Zz4=") !important;
  margin-top: -1px !important;
}

.sort.asc:after {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik02LjUyNiAzLjVjLjQyMyAwIC42MzUtLjQzOC4zMy0uNzA4TDMuODQuMTI1YS41MjkuNTI5IDAgMDAtLjY4MiAwTC4xNDUgMi43OTJjLS4zMDYuMjctLjA5NC43MDguMzMuNzA4aDYuMDV6IiBmaWxsPSIjQjZDMkQyIi8+PC9zdmc+") !important;
  margin-top: -1px !important;
}

.custom-rounded-button {
  padding: 8px;
}

.progress-bar {
  height: 20px !important;
}

.disabled {
  opacity: 70%
}
