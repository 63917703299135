.progress-container {
  margin: 0 auto;
}

.match-line {
  width: 100%;
  height: 1px;
  position: absolute;
  background: #dee2e6;
}

.progress-line {
  height: 2px;
  background: #dee2e6;
  width: 100%;
  margin-bottom: -0.75rem;
}

.steps-container {
  z-index: 1;
  width: 100%;
  span {
    width: 20%;
    text-align: center;
  }
}

.body-color {
  color: $body-color;
}

.text-underline {
  text-decoration: underline;
}
