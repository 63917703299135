.answerContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  .createAnswerContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    margin: 0 20%;

    .titleContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > div {
      width: 100%;
    }
  }

  .program-container {
    margin: 0 20%;
  }

  .logo-container {
    width: 300px;
  }
}

pre {
  white-space: pre-wrap;
}
